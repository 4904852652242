import React from "react";
import "./style.scss";

export default function StickySocial() {
  return (
    <div className="stickysocial">
      <a
        target="_blank"
        href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
        className="stickysocial__icon"
      >
        <i class="fab fa-facebook-f"></i>
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
        className="stickysocial__icon"
      >
        <i class="fab fa-twitter"></i>
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
        className="stickysocial__icon"
      >
        <i class="fab fa-youtube"></i>
      </a>
      <a
        target="_blank"
        href="https://www.facebook.com/Chinmaya-Vidyalaya-Vasant-Vihar-Delhi-136530329794825/"
        className="stickysocial__icon"
      >
        <i class="fab fa-linkedin-in"></i>
      </a>
    </div>
  );
}

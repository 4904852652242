import React, { Component } from "react";
import "./style.scss";

export default function NewsTicker() {

  const data = [{ text: "Admission Open for classes Nursery - Class IX, Class XI", link: "" }]

  let ticketItems = [];

  const repeat = 12; // increase the number to increase the speed

  for (let i = 0; i < repeat; i++) {
    data.forEach(item => {
      ticketItems.push(<div className="ticker-item">
        <a
          href={item.link.length > 0 && item.link}
          target="__blank"
        >
          {item.text}
        </a>
      </div>)
    })
  }

  return (
    <div>
      <div className="tcontainer">
        <div className="ticker-wrap">
          <div className="ticker-move">
            {ticketItems}
          </div>
        </div>
      </div>
    </div>
  );

}


import React, { useEffect } from "react";
import "./style.scss";
import { Link } from "gatsby";
import logo from "../../images/logo.png";

export default function Navbar() {
  useEffect(() => {
    const nav = document.querySelector("nav");
    const layout = document.getElementById("layout");
    let isSticky = false;
    document.addEventListener("scroll", () => {
      let elDistanceToTop = nav.getBoundingClientRect().top;

      //   console.log(elDistanceToTop);
      if (window.scrollY === 0) {
        if (isSticky) {
          nav.classList.remove("navbar--sticky");
          layout.classList.remove("layout--stickypadding");
          isSticky = false;
        }
      }
      if (elDistanceToTop < 0) {
        if (!isSticky) {
          nav.classList.add("navbar--sticky");
          layout.classList.add("layout--stickypadding");

          isSticky = true;
        }
      }
    });
  }, []);

  return (
    <nav className="navbar">
      <Link style={{ textDecoration: "none" }} to="/">
        <div className="navbar__logo">
          <img
            className="navbar__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
          <div className="navbar__logo__text">
            Chinmaya Vidyalaya
            <br />
            Vasant Vihar, Delhi
          </div>
        </div>
      </Link>

      <div className="navbar__links">
        <div className="menu1">

          <div className="navbar__links__link">
            <Link to="/contact-information/">
              Connect <i class="fas fa-chevron-down"></i>
            </Link>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--4">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/alumni/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Alumni
                    </Link>
                    <Link
                      to="/careers/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Careers
                    </Link>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>

          <div className="navbar__links__link">
            <Link to="/contact-information/">Contact Us</Link>
          </div>

          <div className="navbar__links__link">
            <a className="" href="https://cvvv.iwsportal.com:572/Account/Login.aspx" target="_blank">Login</a>

          </div>
          <a className="e-newsletter-btn" href="https://online.fliphtml5.com/xqzyx/stxf/?1667063543327">E-NEWSLETTER</a>
        </div>
        <div className="menu2">
          <div className="navbar__links__link">
            <Link to="/">Home</Link>
          </div>

          <div className="navbar__links__link">
            About Us <i class="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--1">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/chinmaya-mission-trust/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Chinmaya Mission
                    </Link>
                    <Link
                      to="/chinmaya-vision-programme"
                      className="navbar__links__link__dropdown__link"
                    >
                      Chinmaya Vision Program
                    </Link>
                    <Link
                      to="/gurudevs-journey/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Gurudev's Journey
                    </Link>

                    <Link
                      to="/principal-message/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Principal's Message
                    </Link>
                    <Link
                      to="/managing-committee/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Managing Committee
                    </Link>
                    <Link
                      to="/pta-executive-committee/"
                      className="navbar__links__link__dropdown__link"
                    >
                      PTA Executive Committee
                    </Link>
                    <Link
                      to="/hall-of-fame/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Awards
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar__links__link">
            Information <i class="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--2">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-4">
                    <div className="navbar__links__link__dropdown__heading">
                      School Information
                    </div>

                    <Link
                      to="/cbse-corner/"
                      className="navbar__links__link__dropdown__link"
                    >
                      CBSE Corner
                    </Link>
                    <Link
                      to="/transfer-certificate/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Transfer Certificate
                    </Link>
                    <Link
                      to="/rules-and-regulations/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Rules & Regulations
                    </Link>

                    <Link
                      to="/transportation/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Transportation
                    </Link>
                    <Link
                      to="/school-calendar/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Calendar
                    </Link>

                    <Link
                      to="/circulars/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Circulars
                    </Link>
                    <Link
                      to="/school-uniform/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Uniform
                    </Link>
                    <Link
                      to="/infrastructure/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Infrastructure
                    </Link>

                    <Link
                      to="/school-gallery/"
                      className="navbar__links__link__dropdown__link"
                    >
                      School Gallery
                    </Link>

                    <a
                      href="http://abhyudya.chinmayavvdelhi.ac.in/"
                      target="_blank"
                      className="navbar__links__link__dropdown__link"
                    >
                      Annual E-Magazine
                    </a>
                  </div>
                  <div className="col col-4">
                    <Link
                      to="/academics/"
                      className="navbar__links__link__dropdown__heading"
                    >
                      Academics
                    </Link>
                    <Link
                      to="/cbse-results/"
                      className="navbar__links__link__dropdown__link"
                    >
                      CBSE Results
                    </Link>
                    <Link
                      to="/syllabus/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Syllabus
                    </Link>
                    <Link
                      to="/subjects/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Subjects
                    </Link>
                    <Link to="/exam-schedule/" className="navbar__links__link__dropdown__link">
                      Exam Schedule
                    </Link>
                    <a
                      href="https://online.fliphtml5.com/cgopx/umjq/#p=1"
                      target="_blank"
                      className="navbar__links__link__dropdown__link"
                    >
                      Math E-Newsletter
                    </a>
                  </div>
                  <div className="col col-4">
                    <div className="navbar__links__link__dropdown__heading">
                      Co-Curriculars
                    </div>
                    <Link
                      to="/laboratories/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Laboratories
                    </Link>
                    <Link
                      to="/performance-arts/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Performance Arts
                    </Link>
                    <Link
                      to="/sports/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Sports
                    </Link>
                    {/* <Link className="navbar__links__link__dropdown__link">
                      Signature Activities
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar__links__link">
            Admissions <i class="fas fa-chevron-down"></i>
            <div className="navbar__links__link__dropdown navbar__links__link__dropdown--3">
              <div className="navbar__links__link__dropdown__blur"></div>
              <div className="navbar__links__link__dropdown__content">
                <div className="row">
                  <div className="col col-12">
                    <Link
                      to="/nursery-admissions/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Nursery
                    </Link>
                    <Link
                      to="/admissions/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Other Classes
                    </Link>
                    <Link
                      to="/online-registration-process/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Application Process
                    </Link>
                    <Link
                      to="/registrations/"
                      className="navbar__links__link__dropdown__link"
                    >
                      Registrations
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

import React, { useState } from "react";
import "./style.scss";
import { Link } from "gatsby";
import logo from "../../images/logo.png";

export default function NavbarMobile() {
  const [dropdownActive, setDropdownActive] = useState(false);
  function toggleDropdown(id) {
    const dropdown = document.getElementById(id);
    dropdown.classList.toggle("navbarmobile__links__link__dropdown--active");
  }

  return (
    <nav className="navbarmobile noselect">
      <Link style={{ textDecoration: "none" }} to="/">
        <div className="navbarmobile__logo">
          <img
            className="navbarmobile__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
        </div>
      </Link>

      <div
        onClick={() => setDropdownActive(!dropdownActive)}
        className="navbarmobile__hamburger"
      >
        <i class="fas fa-bars"></i>
      </div>

      <div
        className={`navbarmobile__links ${dropdownActive && "navbarmobile__links--active"
          }`}
      >
        <div className="navbarmobile__links__link">
          <Link
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            to="/"
          >
            Home
          </Link>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown1");
            }}
          >
            {" "}
            About <i class="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown1"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--1"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <Link
                    to="/chinmaya-mission-trust/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Chinmaya Mission
                  </Link>
                  <Link
                    to="/chinmaya-vision-programme"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Chinmaya Vision Program
                  </Link>
                  <Link
                    to="/gurudevs-journey/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Gurudev's Journey
                  </Link>

                  <Link
                    to="/principal-message/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Principal's Message
                  </Link>
                  <Link
                    to="/managing-committee/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Managing Committee
                  </Link>
                  <Link
                    to="/pta-executive-committee/"
                    className="navbar__links__link__dropdown__link"
                  >
                    PTA Executive Committee
                  </Link>
                  <Link
                    to="/hall-of-fame/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Awards
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown2");
            }}
          >
            Information <i class="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown2"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--2"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <div className="navbarmobile__links__link__dropdown__heading">
                    School Information
                  </div>

                  <Link
                    to="/cbse-corner/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    CBSE Corner
                  </Link>
                  <Link
                    to="/transfer-certificate/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Transfer Certificate
                  </Link>
                  <Link
                    to="/rules-regulations/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Rules & Regulations
                  </Link>

                  <Link className="navbarmobile__links__link__dropdown__link">
                    Transportation
                  </Link>
                  <Link
                    to="/school-calendar/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Calendar
                  </Link>
                  <Link
                    to="/circulars/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Circulars
                  </Link>
                  <Link
                    to="/school-uniform/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Uniform
                  </Link>
                  <Link
                    to="/infrastructure"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Infrastructure
                  </Link>
                  <Link
                    to="/school-gallery/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    School Gallery
                  </Link>

                  <a
                    href="http://abhyudya.chinmayavvdelhi.ac.in/"
                    target="_blank"
                    className="navbar__links__link__dropdown__link"
                  >
                    Annual E-Magazine
                  </a>
                </div>
                <div className="col col-12">
                  <Link
                    to="/academics/"
                    className="navbarmobile__links__link__dropdown__heading"
                  >
                    Academics
                  </Link>
                  <Link className="navbarmobile__links__link__dropdown__link">
                    CBSE Results
                  </Link>
                  <Link
                    to="/syllabus/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Syllabus
                  </Link>
                  <Link
                    to="/subjects/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Subjects
                  </Link>
                  <Link to="/exam-schedule/" className="navbarmobile__links__link__dropdown__link">
                    Exam Schedule
                  </Link>
                  <a
                    href="https://online.fliphtml5.com/cgopx/umjq/#p=1"
                    target="_blank"
                    className="navbar__links__link__dropdown__link"
                  >
                    Math E-Newsletter
                  </a>
                </div>
                <div className="col col-12">
                  <div className="navbarmobile__links__link__dropdown__heading">
                    Co-Curriculars
                  </div>
                  <Link
                    to="/laboratories/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Laboratories
                  </Link>
                  <Link
                    to="/performance-art/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Performance Arts
                  </Link>
                  <Link
                    to="/sports/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Sports
                  </Link>
                  {/* <Link
                    to="#"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Signature Activities
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <span
            onClick={() => {
              toggleDropdown("dropdown3");
            }}
          >
            Admissions <i class="fas fa-chevron-down"></i>
          </span>
          <div
            id="dropdown3"
            className="navbarmobile__links__link__dropdown navbarmobile__links__link__dropdown--3"
          >
            <div className="navbarmobile__links__link__dropdown__blur"></div>
            <div className="navbarmobile__links__link__dropdown__content">
              <div className="row">
                <div className="col col-12">
                  <Link
                    to="/nursery-admissions/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Nursery
                  </Link>
                  <Link
                    to="/admissions/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Other Classes
                  </Link>
                  <Link
                    to="/online-registration-process/"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Application Process
                  </Link>
                  <Link
                    to="/registrations"
                    className="navbarmobile__links__link__dropdown__link"
                  >
                    Registrations
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="navbarmobile__links__link">
          <Link
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            to="/contact-information/"
          >
            Contact
          </Link>
        </div>
        <div className="navbarmobile__links__link">
          <Link
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            to="/careers/"
          >
            Careers
          </Link>
        </div>
        <div className="navbarmobile__links__link">
          <Link
            style={{
              textDecoration: "none",
              color: "#182951",
              display: "block",
            }}
            to="/alumni/"
          >
            Alumni
          </Link>
        </div>
        <div
          className="navbarmobile__links__link"
        >
          <a href="https://cvvv.iwsportal.com:572/Account/Login.aspx"> Login </a>

        </div>
        <a className="navbarmobile__links__link e-newsletter-btn" href="https://online.fliphtml5.com/xqzyx/stxf/?1667063543327">E-NEWSLETTER</a>
      </div>
    </nav>
  );
}
